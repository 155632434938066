.modal {
    /* display: none; */
    /* display: block; */
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.103);
}

.close-modal {
    display: none;
}

.show-modal {
    display: block;
}

.modal-centre {
    width: 100%;
    min-height: 100vh;
    max-height: 900px;
    /* height: 100%; */
    /* background-color: yellow; */
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-area {
    /* background-color: yellowgreen; */
    /* width: 50px;
    height: 50px; */
    /* position: relative; */
    height: 100%;
    display: flex;
    align-items: center;
}

.content-area .close {
    background-color: beige;
    position: absolute;
    right: -14px;
    top: -14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    width: 14px;
    height: 14px;
}

