.otp-box {
    background-color: #ffffff;
    width: 358px;
    /* border: 1px solid #4650E5;
    box-sizing: border-box; */
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.textfield-box {
    background-color: #ffffff;

    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 2px 2px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    outline: none;
}

.textfield-active {
    border: 1px solid #4650E5;
}

.first-textfield {
    border: 1px solid rgba(67, 61, 246, 0.2);
    border-width: 0 0 0 1px;
}

.other-textfield {
    border-width: 0;
}

.textfield {
    /* box-sizing: border-box; */
    box-sizing: border-box;
    padding: 16px 16px;
    width: 100%;
    height: 80%;
    font-family: Rubik, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    outline: none;
}


input.textfield:focus {
    color: #1B2124;
    background: #ffffff;
    /* font-size: 30px; */
  }

.textfield input:hover {
    background-color: #1B2124;
}


/* .focus-element {
    
} */
