*{
    padding: 0;
    margin: 0;
}


.box-management {
    color: #1B0E44;
    min-height: 100vh;
    width: 100%;
    /* background-image: url('../../../components/asset/background-blue.svg'); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.logo-area-reg {
    width: 130px;
    position: absolute;
    top: 2%;
    left: 80%;
}

.central-area-auth {
    background-color: #FFFFFF;
    min-height: 80vh;
    width: 415px;
    /* margin-top: 128px; */
    display: flex;
    flex-direction: column;
    padding: 54px 25px;
    /* padding: 128px 25px; */
    /* justify-content: space-between; */
}


.text-info-area {
    margin-top: 17px;
    margin-bottom: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    font-family: Rubik, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;

}



.text-area{
    color: #1B0E44;
    font-family: Rubik, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    text-align: left;
    letter-spacing: 0px;
}

.text-form-area-main {
    /* background-color: gray; */
    height: auto;
    /* margin-top: 40px; */
    /* margin-bottom: 60px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.checkbox-area {
    /* background-color: gray; */
    margin-bottom: 35px;
}

.information-elements {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.information-elements a {
    /* margin-top: 10px; */
    margin-bottom: 10px;
    font-weight: 500;


}

.information-elements span {
    font-weight: 400;
    margin-right: 5px;

}

.information-elements, 
.information-elements a {
    color: #FFFFFF;
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;

    font-family: Rubik, serif;
    font-size: 13px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
}
