/* body {
    font-family: 'Lato', sans-serif;
    color: rgba(0, 0, 0, 0.686);
    
} */

.receipt-container {
    background-color: #c0c0c010;
    width: auto;
    margin: 0 auto;
    padding: 20px;
    color: rgba(0, 0, 0, 0.686);
    letter-spacing: .4px;
}

.receipt-header {
    text-align: center;
}

.receipt-logo {
    padding: .8rem;
}

.receipt-logo img {
    width: 20%;
}

.title_1 {
    font-weight: 700;
    font-size: .9rem;
    text-transform: uppercase;
}

.title_2 {
    font-weight: 700;
    font-size: .8rem;
    margin-top: 10px;
    text-transform: uppercase;
}

.title_3 {
    font-weight: 500;
    margin-top: 5px;
    font-size: .7rem;
    color: #bbb;
    text-transform: uppercase;
}

.title_4 {
    margin-top: 10px;
    font-size: .7rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: initial;
}

.title_4>div {
    font-weight: 700;
}

.title_4>span {
    letter-spacing: .5rem;
}

.body-section {
    margin-top: 20px;
}

.brand_title {
    font-weight: 700;
    font-size: .9rem;
    text-transform: uppercase;
}

.receipt-transaction-details>div {
    display: flex;
    width: 50%;
    margin-top: 8px;
    justify-content: space-between;
    font-size: .7rem;
}

.receipt-transaction-details>div>span:first-child {
    font-weight: 600;
    text-transform: uppercase;
}

.total {
    margin-top: 20px;
    text-align: center;
}

.total>div {
    font-size: 1rem;
    font-weight: 600;
}

.footer {
    margin-top: 20px;
    padding: 10px 40px 10px 40px;
    border: dotted #d0d0d08a;
    font-size: .8rem;
    line-height: 30px;
    border-radius: 10px;
}

.footer div:first-child {
    font-weight: 600;
}

.footer>div>span {
    padding-inline: 20px;
}

@media print {
    body {
        margin: 0;
    }
    .receipt-container {
        width: 100%;
    }
}