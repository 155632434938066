.logoicon {
    width: 82px;
    height: 30px;
    object-fit: contain;
}

.sidebar-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.main-area {
    width: auto;
    margin-top: 50px;
}

.select-anchor {
    text-decoration: none!Important;
}

.select-anchor-mini {
    width: 58px;
}

.select-anchor-mini .selectbox svg {
    margin-left: 12px;
}

#sidebar-wrapper-mini {
    min-height: 100vh;
    width: auto;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    text-align: center;
}