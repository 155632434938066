.select-anchor {
    /* background-color: bisque; */
    display: flex;
    flex-direction: column;
    height: 52px;
    width: 100%;
    font-family: Rubik, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
    margin-bottom: 10px;
}

.selectbox {
    background-color: #1B0E44;
    display: flex;
    width: 88%;
    align-items: center;
    margin-left: 18px;
    border-radius: 12px;
    justify-content: flex-start;
}

.selectbox::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 52px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    transform: translate(-17px, 0);
}

.selection-name {
    margin-left: 20px;
}

.select-anchor:hover {
    opacity: 0.9;
}

.active-selectbox::before {
    background-color: #1B0E44;
    /* position: relative; */
    /* right: 7px; */
}

.active-selectbox {
    color: #fff;
}

.active-selectbox svg {
    fill: #fff;
}

.inactive-selectbox::before {
    background-color: #fff;
}

.inactive-selectbox {
    color: #0D0D0D;
    background-color: transparent;
}

.inactive-selectbox svg {
    fill: #0D0D0D;
}

.select-anchor .selectbox svg {
    margin-left: 15px;
}