.tr-receipt {
    padding: 30px;
}

.tr-receipt-title {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
}

.tr-receipt-jumbotron {
    background: #F4F5F7;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.tr-receipt-amount {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.2px;
    color: #1B0E44;
}

.tr-receipt-name {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #8D9091;
}

.tr-receipt-list {
    margin-top: 20px;
}

.tr-receipt-list-title {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #7A869A;
}

.tr-receipt-list-value {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    color: #0D0D0D;
}