.box-management-main {
    min-height: 100vh;
    width: 100%;
    flex-wrap: wrap;
}

.transaction-pseudo-body {
    padding: 50px 50px;
    min-height: 100vh;
    width: 100%;
    display: block;
    flex-direction: column;
    justify-content: center;
}

.filter-card {
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

@media (max-width: 920px) {
    .filter-card {
        display: grid;
        grid-template-rows: 1fr;
        grid-gap: 20px;
    }
    .trans-first {
        display: none!Important;
    }
    .trans-second {
        margin-left: 0!Important;
    }
}

.search-input {
    border: none;
    outline: none;
}

.trans-first {
    border-right: 1px solid #EFEFEF;
    padding-right: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.trans-first-inner {
    height: 100%;
    width: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-input-main {
    display: flex;
    align-items: center;
}

.filter-text {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #4650E5;
    margin-left: 10px;
    cursor: pointer;
}

.trans-second {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 30px;
}

.filter-settings {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
}

.text-form-area {
    width: 100%;
}