.row .bill-logo-content {
    display: flex!Important;
    justify-content: space-between;
}

.bill-main {
    display: flex!Important;
    justify-content: center!Important;
    flex-direction: column;
    height: 100%;
}

.bill-inner-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.btn-support {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    position: absolute;
    width: 148px;
    height: 44px;
    right: 42px;
    bottom: 26px;
    color: #fff;
    background: #1B0E44;
    box-shadow: 0px 71px 80px rgba(27, 14, 68, 0.0282725), 0px 15.8588px 17.869px rgba(27, 14, 68, 0.0417275), 0px 4.72157px 5.32008px rgba(27, 14, 68, 0.07);
    border-radius: 12px;
}

.bill-box-management .box-header {
    text-align: center!Important;
}

.bill-box-management {
    /* margin-top: 40px; */
}

/* .box-card {
    width: 453px;
    height: 420px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
} */

.buy-text {
    height: 24px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #000000;
}

.amt-subtext {
    height: 16px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #8D9091;
}

/* .back-text {
    margin-top: 40px;
    height: 16px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.back-text img {
    padding-right: 10px;
} */

.bill-resolve-text {
    display: flex;
}

.bill-resolve-text small {
    margin-left: 10px;
}

.bill-central-area {
    background-color: #FFFFFF;
    height: auto;
    width: auto;
    border-radius: 12px;
    padding: 40px;
}

.form-resolver {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}


.first-step-power-pay {
    color: #000;
    display: flex;
    align-items: center;
    flex-direction: column;
}


.first-main-area {
    width: 100%;
    background: #FFFFFF;
    
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03);
    border-radius: 12px;

    width: auto;
    padding: 35px;
    min-height: 400px;

    display: flex;
    flex-direction: column;


}

.first-close-area{
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    /* Neutral/N20 */
    background: #F4F5F7;
    border-radius: 10px;
}


.add-card{
    height: 79px;
    width: 100%;
    border-radius: 12px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 29px;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    
    color: #FFFFFF;

    background: linear-gradient(267.21deg, #2B1172 2.21%, #13073E 97.79%);
}


