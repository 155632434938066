html {
    /* background: url('../../assets/imgs/sign-up-bg@3x.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
}

.ant-table {
    overflow-x: scroll;
}