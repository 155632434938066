.box {
    display: flex;
    background: #F7F8F9;
    align-items: center;
    justify-content: center;
}








.profile-image {
    width: 190px;
    height: 190px;
    border: 2px solid #1B0E44;
    box-sizing: border-box;
    border-radius: 8px;
}

.loader-position {
    display: flex;
    justify-content: center;
    align-items: center;
    
}







