* {
    padding: 0;
    margin: 0;
}

.box-management-reg {
    color: #1B0E44;
    min-height: 100vh;
    width: 100%;
    /* background-image: url('../../../components/asset/background-blue.svg'); */
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: row;
}

.text-area-reg {
    color: #1B0E44;
    font-family: Rubik, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 145%;
    text-align: left;
}

.text-form-area-reg {
    /* background-color: gray; */
    height: 162px;
    margin-top: 38px;
    /* margin-bottom: 29px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.information-elements {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.information-elements a {
    /* margin-top: 10px; */
    margin-bottom: 10px;
    font-weight: 500;
}

.information-elements span {
    font-weight: 400;
    margin-right: 5px;
}

.information-elements, .information-elements a {
    color: #FFFFFF;
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;
    font-family: Rubik, serif;
    font-size: 13px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
}

/*  */

/*  */

/*  */

.leftContent {
    background-color: #FFFFFF;
    width: auto;
    min-height: 900px;
    padding: 82px 88px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.text-info-area-reg {
    margin-top: 17px;
    margin-bottom: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-family: Rubik, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;
}

.user-type-box {
    display: flex;
    padding: 0px 0px;
    /* padding-left: 0px; */
    margin-bottom: 48px;
}

.userType {
    /* background-color: red; */
    height: 70px;
    width: 173px;
    border-radius: 4px;
    margin-right: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.8px solid #8D9091;
    border-radius: 4px;
}

.iconArea {
    padding-right: 17px;
}

.notSelected svg {
    fill: #001956;
    fill-opacity: 0.5;
}

.now-selected svg {
    fill: #001956;
    fill-opacity: 1;
}

.descriptionArea {
    display: flex;
    flex-direction: column;
}

.descriptionArea div {
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    color: #000000;
}

.descriptionArea p {
    font-family: Rubik;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    color: #444444;
}

.input-box-reg {
    /* background-color: gray; */
    height: 240px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.checkbox-area-reg {
    /* background-color: gray; */
    margin-bottom: 50px;
    margin-top: 30px;
}

.check-label-reg {
    margin-left: 16px;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.anchor-decor {
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    text-decoration: none;
    color: blue;
}

.bottom-info {
    /* background-color: gray; */
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom-info span {
    margin-right: 10px;
}

/* .rightContent {
    background-color: black;
} */

.rightContent {
    /* background-color: #af7e7e; */
    min-height: 900px;
    width: 100%;
    background-image: url('../../../components/asset/BG.jpeg');
    position: relative;
    background-size: cover;
}

.logo-area-reg {
    width: 130px;
    height: 32px;
    position: absolute;
    left: 80%;
    top: 5%;
}

@media (min-width:1466px) {
    .leftContent {
        
    }
    .rightContent {
        display: none;
    }

    /* .box-management-reg {
        width: 100%;
        display: inline-block;
    } */
}

@media (max-width: 1095px) {
    .rightContent {
        display: none;
    }
}

@media (max-width: 438px) {
    .leftContent {
        padding: 20px;
        margin-top: 50px;
    }
}