.dropdown {
    width: 100%;
    padding: 16px 16px;
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #8D9091;
}

.dropdown-disabled {
    opacity: 0.7;
    cursor: not-allowed;
    flex-direction: row!Important;
}