.deactivate-box .body-section {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 145%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4E4E4E;
    opacity: 0.7;
}

.deactivate-box .btn-area {
    margin-top: 40px;
}

.deactivate-box .text-area>.title {
    margin-top: 30px;
}