/* .success-card {
    height: auto;
    width: 453px;
    padding: 42px;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
} */

.success-card-body {
    display: flex;
    flex-direction: column;
}

/* .thick-deco {
    background-color: red;
    padding: 4px;
} */

.first-circle {
    content: '';
    background-color: #FFFFFF;
    height: 67.19998931884766px;
    width: 68.7272720336914px;
    margin-top: 2px;
    border-radius: 50%;
    border: 3px solid #6AB04C;
}

.second-circle {
    content: '';
    background-color: #FFFFFF;
    height: 59.20000076293945px;
    width: 60.54545593261719px;
    left: 5.7275390625px;
    top: 6.399993896484375px;
    border-radius: 50%;
    border: 5px solid rgba(41, 204, 162, 0.248);
}

.success-card-body .title {
    font-family: Rubik;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: center;
    color: #6AB04C;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.success-card-body .body-section {
    margin: auto 31px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #4E4E4E;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 145%;
    /* or 22px */
    opacity: 0.7;
}

.reference {
    display: flex;
    justify-content: center;
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 145%;
    /* identical to box height, or 19px */
    display: flex;
    align-items: center;
    text-align: center;
    /* Brand / Dark */
    color: #1B0E44;
    opacity: 0.7;
}

.success-card-body .reference {
    margin-top: 10px;
}

.btn-area {
    /* background-color: black; */
    margin-top: 30px;
}

.first-circle, .second-circle, .thick-deco, .btn-area {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}