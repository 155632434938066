.box {
    height: 430px;
    width: 453px;
    border-radius: 12px;


    background: #FFFFFF;

    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03);
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}


/* .thick-deco {
    background-color: red;
    padding: 4px;
} */

.first-circle {
    content: '';
    background-color: #FFFFFF;
    height: 67.19998931884766px;
    width: 68.7272720336914px;
    margin-top: 2px;
    border-radius: 50%;
    border: 3px solid #E02020;
    
}

.second-circle {
    content: '';
    background-color: #FFFFFF;
    height: 59.20000076293945px;
    width: 60.54545593261719px;
    left: 5.7275390625px;
    top: 6.399993896484375px;

    border-radius: 50%;
    border: 5px solid rgba(238, 64, 52, 0.248);
}

.text-area {
    /* background-color: green; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
}


.text-area .title {
    font-family: Rubik;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: center;
    color: #E20010;

    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-area .body-section {
    margin: auto 31px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #4E4E4E;
}

.text-area .reference {
    margin-top: 10px;
}
.btn-area {
    /* background-color: black; */
}



.first-circle,
.second-circle,
.thick-deco,
.btn-area {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}