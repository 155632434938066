.table-container {
    height: auto;
    width: 100%;
    border-radius: 12px;
    padding: 34px 30px;
    background: #FFFFFF;
    font-family: Rubik, serif;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.06);
    margin: auto;
    box-sizing: border-box;
    overflow-x: auto;
}

.table-container table {
    width: 100%;
}

.table-container table thead {
    display: table-header-group;
    vertical-align: middle;
}

.table-container table tbody tr {
    background-color: #FFFFFF;
    height: 64px;
    border-radius: 0px;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #000000;
}

.table-container table tbody tr:nth-child(2n+0) {
    background-color: #FBFBFB;
}

.table-container table tbody tr:nth-child(2n+0):hover {
    opacity: 0.5;
}

.table-container table tbody tr:nth-child(2n+1):hover {
    background-color: rgba(251, 251, 251, 0.596);
}

.table-head-container {
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    /* padding: 20px 0; */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #8D9091;
}

.table-head-container h4 {
    margin-right: 8px;
}

.arrow-container {
    display: flex;
    flex-direction: column;
}

.arrow-up {
    height: 6.400000095367432px;
    width: 8px;
    border-radius: 0.699999988079071px;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
    margin-bottom: 1.2px;
}

.arrow-down {
    height: 6.400000095367432px;
    width: 8px;
    border-radius: 0.699999988079071px;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    margin-top: 1.2px;
}

.arrow-selected {
    background-color: #000000;
}

.arrow-unselected {
    background-color: #CCCCCC;
}

.arrow-unselected:hover, .arrow-selected:hover {
    opacity: 0.5;
}

.controls-checkbox {
    height: 8px;
    width: 8px;
    border-radius: 4px;
}

.c-c-green {
    background: #22B02E;
    border: 1px solid #22B02E;
    box-sizing: border-box;
}

.c-c-pinky {
    background: #C5292A;
    border: 1px solid #C5292A;
    box-sizing: border-box;
}

.table-head-container h4 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */
    display: flex;
    align-items: center;
    color: #8D9091;
}

tr {
    cursor: pointer;
}

td {
    padding: 10px;
}