.logoicon {
    width: 82px;
    height: 30px;
    object-fit: contain;
}

.sidebar-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.main-area {
    width: auto;
    margin-top: 50px;
}

.select-anchor {
    text-decoration: none!Important;
}

.dashboard-logo {
    width: 100%;
}

#side-wrapper {
    width: 350px;
}