/* *{
    padding: 0;
    margin: 0;
} */


/* .box-management {
    color: #1B0E44;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} */


.upgraact-central-area {
    background-color: #FFFFFF;

    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03);
    border-radius: 12px;

    /* height: 475px; */
    width: auto;
    border-radius: 12px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    /* justify-content: space-between; */
}

.text-area{
    color: #1B0E44;
    font-family: Rubik, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 145%;
    text-align: left;
}

.upgraact-text-form-area {
    /* background-color: gray; */
    height: 182px;
    margin-top: 30px;
    /* margin-bottom: 29px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.checkbox-area {
    /* background-color: gray; */
    margin-bottom: 35px;
}

.information-elements {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.information-elements a {
    /* margin-top: 10px; */
    margin-bottom: 10px;
    font-weight: 500;


}

.information-elements span {
    font-weight: 400;
    margin-right: 5px;

}

.information-elements, 
.information-elements a {
    color: #FFFFFF;
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;

    font-family: Rubik, serif;
    font-size: 13px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
}

.iconed-label{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}


.upgraact-text-info-area {
    margin-top: 8px;
    /* margin-bottom: 17px; */
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;

    font-family: Rubik, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;

}

.upgraact-lock {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #333333;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upgraact-lock i {
    height: 9px;
    width: 8px;
    font-size: x-small;
}

/* .upgraact-submit-button {
    display: flex;
    justify-content: space-between;
} */
/* 
.upgraact-submit-button .first-btn{
    width: 117px;
}

.upgraact-submit-button .second-btn {
    width: 240px;
} */