.settings-box-management {
    color: #1B0E44;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.settings-pseudo-body {
    padding: 50px 50px;
    min-height: 100vh;
    width: auto;
    min-width: 100%;
    display: flex;
    justify-content: center;
}

.settings-central-area {
    height: auto;
    width: 100%;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    padding: 50px 50px 0 50px;
    margin-bottom: 98px;
}

.settings-central-bg-color {
    background-color: #FFFFFF;
}

.setting-business-file {
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #8D9091;
}

.BK-SE-settings-central-area {
    height: fit-content;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 50px;
    background-color: #FFFFFF;
}

.tier-text {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.first-close-area{
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    /* Neutral/N20 */
    background: #F4F5F7;
    border-radius: 10px;
}


.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.change-pass-modal-container {
    color: #000;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.data-collection-area{
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 5%), 0px 25px 35px rgb(0 0 0 / 3%);
    border-radius: 12px;
    width: auto;
    padding: 35px;
    /* min-height: 400px; */
    display: flex;
    flex-direction: column;
}


@media (max-width: 1045px) {
    .settings-central-area {
        flex-direction: column-reverse;
    }

    .right-column{
        margin-bottom: 30px;
    }
}