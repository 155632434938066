*{
    padding: 0;
    margin: 0;
}

html {
    background-color: #F7F8F9!Important;
}

.login-box-management {
    color: #1B0E44;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.box-bg {
    background-image: url('../../../components/asset/background-blue.svg');
}

.logo-area-login {
    /* width: 130px; */
    margin-bottom: 15px;
    margin-top: 40px;
}

.text-area{
    color: #1B0E44;
    font-family: Rubik, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 145%;
    text-align: left;
}

.text-form-area {
    /* background-color: gray; */
    height: auto;
    /* margin-top: 38px; */
    /* margin-bottom: 29px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.checkbox-area {
    /* background-color: gray; */
    margin-bottom: 35px;
}

.information-elements {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.information-elements a {
    /* margin-top: 10px; */
    margin-bottom: 10px;
    font-weight: 500;


}

.information-elements span {
    font-weight: 400;
    margin-right: 5px;

}

.information-elements, 
.information-elements a {
    color: #FFFFFF;
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;

    font-family: Rubik, serif;
    font-size: 13px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
}

.selected-label {
    width: 100%;
}