.setttings-nav {
    height: 64px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px #8D9091;

    display: flex;
    align-items: center;
}

.settings-nav-item {
    background-color: #FFFFFF;
    padding: 20px;
    color: #000;
    position: relative;
    /* border-radius: 8px 8px 0px 0px; */
    
    cursor: pointer;

    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;

    text-decoration: none!important;

}

.settings-nav-item:hover {
    /* text-decoration: none; */
    color: rgba(0, 0, 0, 0.61);
}
/* .settings-active {
    border-bottom: 4px solid #1B0E44;
} */

.bottom-style {
    position: absolute;
    height: 4px;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    background: #1B0E44;
    left: 0;
    bottom: 0;
    display: none;
}

.settings-active {
    display: block;
}

.settings-inactive {
    display: none;
}



