.box-management-main {
    min-height: 100vh;
    width: 100%;
    flex-wrap: wrap;
}

.filter-card {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    box-sizing: border-box;
    min-height: 60px;
}

.search-input {
    border: none;
    outline: none;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    display: flex;
    align-items: center;
    color: #8D9091;
}

.trans-first {
    /* height: 100%; */
    /* display: flex;
    flex-direction: row; */
    /* background: red; */
    border-right: 1px solid #EFEFEF;
    padding-right: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* transform: rotate(90deg); */
}

.trans-first-inner {
    height: 100%;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-input-main {
    display: flex;
    align-items: center;
}

.filter-text {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #4650E5;
    margin-left: 10px;
    cursor: pointer;
}

.trans-second {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 30px;
}

.filter-settings {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */
    display: flex;
    align-items: center;
    /* Primary / Black */
    color: #000000;
}

.text-form-area {
    width: 100%;
}

.overview-container {
    display: flex;
    justify-content: center;
    height: auto;
}

.overview-container img {
    width: 100%;
}

.overview-content {
    position: absolute;
    /* height: 276px; */
    width: fit-content;
    /* width: calc(0.7 * 100vw); */
    /* padding: 30px; */
    box-sizing: border-box;
    /* display: flex;
    align-items: center; */
}

.overview-mobile {
    display: none;
}

.overview-title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
}

.overview-t1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
}

.overview-t2 {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.overview-t3 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    opacity: 0.7;
}

.overview-balance {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
}

.overview-card-title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
}

.overview-card-t1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #9B9B9B;
    margin-bottom: 7px;
}

.overview-card-t2 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #000000;
}

.overview-card {
    padding: 21px 21px!Important;
}

.overview-left {
    float: left;
    padding: 30px;
}

.overview-right {
    float: right;
    padding: 16px;
}

.overview-actions {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin: 0 auto;
}

.monthly-target {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
}

.target-process {
    width: 100%;
    height: 100px;
    background: #001956;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.target-amount {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
}

.target-pecentage {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    opacity: 0.7;
}

.target-merchant {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    /* or 160% */
    letter-spacing: 0.5px;
    color: #363636;
}

.overview-chart {
    display: block;
    /* grid-template-columns: 3fr 1fr; */
    /* grid-gap: 10px; */
}

.chart-main {
    font-family: 'Rubik';
    font-size: 0.7rem;
}

@media screen and (max-width: 1395px) {
    .overview-right {
        display: none;
    }
    .overview-left {
        float: none;
        text-align: center;
    }
    .overview-balance {
        justify-content: space-evenly;
    }
    .overview-t2 {
        font-size: 22px;
    }
    .overview-t3 {
        font-size: 12px;
    }
    .overview-t2 {
        margin-bottom: 10px;
    }
    .overview-title {
        margin-top: 0px;
    }
    .overview-content {
        display: flex;
        align-items: center;
    }
    .overview-balance {
        margin-top: 18px;
    }
}

@media (min-width: 1200px) {
    .overview-actions {
        grid-template-columns: repeat(4, 1fr);
    }
    .overview-chart {
        grid-template-columns: 3fr 1fr;
    }
}

@media (max-width: 1120px) {
    .overview-actions {
        grid-template-columns: repeat(2, 1fr);
    }
    .overview-chart {
        grid-template-columns: none;
    }
}

@media screen and (max-width: 1095px) {
    .overview-title {
        font-size: 26px;
    }
    .overview-t2 {
        font-size: 18px;
    }
    .overview-t3 {
        font-size: 12px;
    }
    .overview-t2 {
        margin-bottom: 3px;
    }
}

@media screen and (max-width: 992px) {
    .overview-title {
        font-size: 24px;
        margin-bottom: 3px;
    }
    .overview-t3 {
        font-size: 11px;
    }
    .overview-mobile {
        display: block;
        text-align: center;
        /* margin-top: 10px; */
    }
    .overview-title-mobile {
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        /* letter-spacing: 3px; */
    }
    .overview-left {
        display: none;
    }
    .overview-t2 {
        font-size: 1.2rem;
    }

    .overview-content {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 767px) {
    .overview-actions {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 438px) {
    .overview-actions {
        grid-template-columns: repeat(1, 1fr);
    }
}