.details-title {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    /* identical to box height, or 240% */
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #666666;
}

.details-text {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    display: flex;
    align-items: center;
    /* Primary / Black */
    color: #000000;
}

.details-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.details-sub {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.details-sub-inner {}

.box-core {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
}

/* .box-card-details {
    height: auto;
    width: 100%;
} */

.merchant-card {
    width: 453px!Important;
}

.merchant-profile-inner {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}