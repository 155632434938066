.card-custom {
    /* height: 360px;
    width: 692px; */
    height: auto;
    width: auto;
    border-radius: 12px;
    padding: 34px 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.06);
    margin: auto;
    box-sizing: border-box;
}

.box-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 453px;
    height: auto!Important;
    padding: 52px 45px!Important;
    max-width: -webkit-fill-available;

    height: 420px;
    background: #FFFFFF;
    /* Shadow #1 */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
}