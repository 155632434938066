.btn-custom {
    padding: 8px 24px;
    /* display: inline-block; */
    text-decoration: none;
    font-weight: 400;
    font-style: normal;
    font-family: Rubik, serif;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column!Important; */
    cursor: pointer;
    text-decoration: none!Important;
    cursor: pointer;
}

.btn-link:hover {
    color: initial!Important;
    /* opacity: 0.8; */
}

.btn-disabled {
    opacity: 0.7;
    cursor: not-allowed;
    flex-direction: row!Important;
}

.py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.py-6-px-16 {
    padding: 6px 16px;
}

.tile-font {
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
}

.btn-round-corner {
    border-radius: 8px;
}

.btn-round {
    border-radius: 50px;
}

.btn-default {
    background-color: #ddd;
    color: #000;
}

.btn-blue {
    background-color: #1B0E44;
    color: #FFFFFF;
}

.btn-blue +.btn-link:hover {
    color: #fff!Important;
    /* opacity: 0.8; */
}

.btn-green {
    background-color: #6AB04C;
    color: #FFFFFF;
}

.btn-green-text {
    background: rgba(34, 176, 46, 0.1);
    color: #22B02E;
}

.btn-export {
    padding: 16px 24px;
    text-decoration: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 8px;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    background: #4650E5;
    border-radius: 8px;
}

.btn-export:hover {
    color: #F4F4F4;
}

.btn-white {
    color: #000;
    background: #fff;
}

.small-button {
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    display: flex;
    justify-content: center;
    padding: 0;
    padding-top: 8px;
    padding-bottom: 8px;
}

.btn-icon {
    flex-direction: row!Important;
}

.btn-logout {
    background: #F9EAEB;
    /* opacity: 0.1; */
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 0px;
    /* or 0% */
    display: flex;
    align-items: center;
    text-align: center;
    color: #C5292A;
}