.check-box {
    width: auto;
    padding: 0 0;
    display: flex;
    /* align-items: center; */
    justify-content: left;
}

.check-box input {
    appearance: none;
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border: 1px solid #443DF6;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

.check-box label {
    font-family: Rubik, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.check-box input:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00c";
    font-size: 10px;
    color: #443DF6;
    display: none;
}

.check-box input:hover {
    opacity: 0.8;
}

.check-box input:checked {
    background-color: #ffffff;
}

.check-box input:checked:after {
    display: block;
}

.check-box .check-label {
    margin-left: 10px;
}