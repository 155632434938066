/* *{
    padding: 0;
    margin: 0;
} */

.box-management {
    color: #1B0E44;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.box-bg {
    background-image: url('../../../components/asset/background-blue.svg');
}

.logo-area {
    width: 130px;
    margin-bottom: 65px;
}

.key-area{
    /* background-color: gray; */
    display: flex;
    justify-content: center;
}

.text-info-area-for {
    /* background-color: yellow; */
    height: 110px;
    /* margin-top: 38px; */
    /* margin-bottom: 29px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    font-family: Rubik, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;

}

.email-sub-area {
    /* background-color: gray; */
    /* margin-bottom: 35px; */
}
