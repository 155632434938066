.selected {
    background-color: #ffffff;
    border: 1px solid #4650E5;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Rubik, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    /* margin-bottom: 8px; */
    /* margin-bottom: 20px; */
    color: f5f6fa;
    position: relative;
    cursor: pointer;
    

    /* addition for icon */
    /* display: flex; */

    /* display: flex;
    justify-content: space-between;
    align-items: center;

    flex-direction: row-reverse; */
}

/* addition of icon to text input */

.right-icon {
    /* background-color: aqua; */
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;    
    align-items: center;
    /* width: fit-content; */
}

.left-icon {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: row;    
    align-items: center;
}

.iconic-div {
    /* background-color: aquamarine; */
    padding-right: 5px;
    width: min-content;
    display: none;
}

.right-icon .iconic-div {
    display: block;
}

.left-icon .iconic-div {
    display: block;
}

/* addition of icon */

.select-item-name-show {
    font-weight: initial!Important;
}

.textfield {
    border: 1px solid rgba(67, 61, 246, 0.2);
    border-width: 0 0 0 4px;
    /* box-sizing: border-box; */
    box-sizing: border-box;
    padding: 16px 16px;
    width: 100%;
    outline: none;
    font-family: Rubik, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

/* input.textfield {
    background-color: yellow;
    min-width: 100%;
} */

input.textfield:focus {
    color: #1B2124;
    background: #ffffff;
    /* font-size: 30px; */
}

.selected label {
    background-color: #fff;
    padding: 2px;
    position: absolute;
    width: auto;
    left: 10px;
    top: -15px;
    display: none;
    transition: all 0.4s;
}

.selected .select-item-name {
    display: none;
}

.selected .select-item-name-show {
    display: block;
}

/* .selected-label:active , .selected-label:enabled {
    outline: auto 1px red!Important;
} */

.selected {
    border: 1px solid #EFEFEF!Important;
}

/* .upload-label {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 550px;
}

input[type="file"] {
    display: none;
} */
