.navIcon {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #fff;
    margin: 0 3px 0 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-search {
    margin-left: 70px;
}

.nav-search input {
    width: 361px;
    height: 19px;
    text-decoration: none;
    font-family: Avenir;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(3, 1, 66, 0.6);
    border: none;
}

.nav-search input:focus {
    border: none;
}

.app-container {
    width: 75%;
    margin: 20px 20px auto;
    float: right;
}

.content-inner {
    margin-top: 30px;
}

.mt-20 {
    margin-top: 20px;
}

.support-area {
    position: fixed;
    width: 148px;
    /* position: absolute; */
    /* left: 85%; */
    bottom: 20px;
    right: 50px;
}

.ant-table {
    overflow-x: scroll;
}
