.navIcon {
    cursor: pointer;
}

.main-navbar {
    height: 80px;
}

.page-description {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 24px; */
    letter-spacing: 0px;
    text-align: center;
}

.page-description div {
    margin-left: 28px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */
    display: flex;
    align-items: center;
    color: #000000;
}