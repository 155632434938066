.verify-box-management {
    color: #1B0E44;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    flex-wrap: wrap;
}

.verify-logo-area {
    width: 100%;
    text-align: center;
}

.verify-info-area {
    margin: 15px auto;
    width: 100%;
    max-width: 655px;
    height: 66px;
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    color: #4E4E4E;
}

.verify-central-area {
    background-color: #FFFFFF;
    height: auto;
    max-width: 692px;
    width: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 98px;
}

.verify-heading {
    color: #1B0E44;
    font-family: Rubik, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: center;
}

.verify-card-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 33px 30px;
}

.with-border {
    border-bottom: 2px solid #EEEEEE;
}

.left-div .top-elem {
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
}

.left-div .bottom-elem {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 145%;
    display: flex;
    align-items: center;
    color: #4E4E4E;
    opacity: 0.7;
}

.right-div {
    cursor: pointer;
}

.modal-info {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 145%;
    display: flex;
    align-items: center;
    color: #4E4E4E;
    opacity: 0.7;
    width: 373px;
}

.upgraact-submit-button {
    display: flex;
    justify-content: space-between;
}

.upgraact-submit-button .first-btn {
    width: 34%;
}

.upgraact-submit-button .second-btn {
    width: 60%;
}

@media screen and (max-width: 465px) {
    .verify-card-component {
        display: block;
        text-align: center;
    }
    .right-div {
        margin-top: 20px;
    }
    .upgraact-submit-button {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }
    .upgraact-submit-button .first-btn {
        width: 100%;
    }
    .upgraact-submit-button .second-btn {
        width: 100%;
    }
    .otp-box, .modal-info {
        width: auto;
    }
}