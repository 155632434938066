.label {
    margin: 0px 10px;
    padding: 0px, 4px;
    font-family: Rubik, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
}

.label-text {
    
}

.label-primary {
    color: #4650E5;
}

.label-green {
    width: 138px;
    height: 26px;
    left: 777px;
    bottom: 19px;
    background: rgba(34, 176, 46, 0.1);
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #22B02E;
}