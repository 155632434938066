.overview-box-management {
    color: #1B0E44;
    min-height: auto;
    max-width: 100%;
    flex-wrap: wrap;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(4, 1fr);
}


.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.change-pass-modal-container {
    color: #000;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.data-collection-area{
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 5%), 0px 25px 35px rgb(0 0 0 / 3%);
    border-radius: 12px;
    min-width: 358px;
    padding: 35px;
    /* min-height: 400px; */
    display: flex;
    flex-direction: column;
}



@media (max-width: 1380px) {
    .overview-box-management {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 1180px) {
    .overview-box-management {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 826px) {
    .overview-box-management {
        grid-template-columns: repeat(1, 1fr);
    }
}

.tileContainer {
    display: flex;
    justify-content: space-between;
    min-height: 125px;
    width: auto;
    min-width: 254px;
    border-radius: 12px;
    /* margin-left: 20px; */
    padding: 16px;
    background: #FFFFFF;
    height: fit-content;
    margin-bottom: 20px;
}

@media screen and (max-width: 850px) {
    .tileContainer {
        width: -webkit-fill-available;
    }
}

.title-item {
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;
}

.mid-item {
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: #000000;
    display: flex;
}

.jsx-item {
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
}

.right-container {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    background: #1B0E44;
    display: flex;
    justify-content: center;
    align-items: center;
}