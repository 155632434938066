.tp-container {
    padding: 50px;
    font-family: 'Rubik';
    font-size: .8rem;
    line-height: 1.8;
}

.tp-container h5 {
    letter-spacing: 1.8;
    margin-top: 20px;
}